import { render, staticRenderFns } from "./party-index.vue?vue&type=template&id=36ad4e4c"
import script from "./party-index.vue?vue&type=script&lang=js"
export * from "./party-index.vue?vue&type=script&lang=js"
import style0 from "./party-index.vue?vue&type=style&index=0&id=36ad4e4c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports